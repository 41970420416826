import AOS from 'aos';
//import Atropos from 'atropos/esm/atropos.esm.js';
import GLightbox from 'glightbox';
import luxy from 'luxy.js';
import Ripple from 'tw-elements/dist/src/js/mdb/ripple';

import './ui.js';
import './swiper.js';

// DOMContentLoaded
// Initiate all libraries.

document.addEventListener('DOMContentLoaded', function (event) {

	// AOS
	// Animate On Scroll Library
	// https://michalsnik.github.io/aos/

	AOS.init({
		disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean
		startEvent: 'DOMContentLoaded', // the event dispatched on the documents
		initClassName: 'aos-init', // class applied after initialization
		animatedClassName: 'aos-animate', // class applied on animation
		useClassNames: false, // if true, will add content of `data-aos` 
		disableMutationObserver: false, // disables automatic mutations' 
		debounceDelay: 50, // the delay on debounce used while resizing window 
		throttleDelay: 99, // the delay on throttle used 
		offset: 120, // offset (in px) from the original trigger point
		delay: 0, // values from 0 to 3000, with step 50ms
		duration: 400, // values from 0 to 3000, with step 50ms
		easing: 'ease', // default easing for AOS animations
		once: false, // whether animation should happen only once 
		mirror: false, // whether elements should animate out while scrolling
		anchorPlacement: 'top-bottom', // position of the element regarding to window 
	});


	// Atropos
	// Animate On Scroll Library
	// https://michalsnik.github.io/aos/
	const myAtropos = Atropos({
		el: '.atropos',
		shadow: false
	});


	// Lightbox
	// Lightweight overlay
	// https://min30327.github.io/luxy.js/

	const lightbox = GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true
	});

})


