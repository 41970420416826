import Swiper, { Autoplay, Pagination, EffectCoverflow, Navigation } from 'swiper';

import 'swiper/swiper-bundle.css';

// DOMContentLoaded
// Initiate all libraries.

document.addEventListener('DOMContentLoaded', function (event) {

	// Swiper
	// Animate On Scroll Library
	// https://michalsnik.github.io/aos/

	const sliderSelector = '.swiper-container',
		defaultOptions = {
			breakpointsInverse: true,
			observer: true
		};
	var nSlider = document.querySelectorAll(sliderSelector);
	[].forEach.call(nSlider, function (slider, index, arr) {
		const data = JSON.parse(slider.getAttribute('data-options')) || {};
		slider.options = Object.assign({}, defaultOptions, data);
		var swiper = new Swiper(slider, {
			modules: [Navigation, Pagination, Autoplay, EffectCoverflow],
			...slider.options
		});
	});

	/*
		var swiper = new Swiper(".coverflow", {
			modules: [EffectCoverflow, Pagination],
			effect: "coverflow",
			grabCursor: true,
			centeredSlides: true,
			slidesPerView: "3",
			initialSlide: 2,
			coverflowEffect: {
				rotate: 50,
				stretch: 0,
				depth: 100,
				modifier: 1,
				slideShadows: true,
			},
			pagination: {
				el: ".swiper-pagination",
			},
		});
		*/

})


